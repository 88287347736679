/*! nouislider - 14.6.3 - 11/19/2020 */

// Orginial styles

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-target {
    position: relative
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}

.noUi-connects {
    overflow: hidden;
    z-index: 0
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat
}

.noUi-connect {
    height: 100%;
    width: 100%
}

.noUi-origin {
    height: 10%;
    width: 10%
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto
}

.noUi-vertical .noUi-origin {
    width: 0
}

.noUi-horizontal .noUi-origin {
    height: 0
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute
}

.noUi-touch-area {
    height: 100%;
    width: 100%
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform .3s;
    transition: transform .3s
}

.noUi-state-drag * {
    cursor: inherit !important
}

.noUi-horizontal {
    height: 18px
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -10px;
    top: -6px
}

.noUi-vertical {
    width: 18px
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    right: -6px;
    top: -17px
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    left: -17px;
    right: auto
}

.noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-connects {
    border-radius: 3px
}

.noUi-connect {
    background: #3FB8AF
}

.noUi-draggable {
    cursor: ew-resize
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after,
.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px
}

.noUi-handle:after {
    left: 17px
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}

.noUi-vertical .noUi-handle:after {
    top: 17px
}

[disabled] .noUi-connect {
    background: #B8B8B8
}

[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
    cursor: not-allowed
}

.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-pips {
    position: absolute;
    color: #999
}

.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px
}

.noUi-marker {
    position: absolute;
    background: #CCC
}

.noUi-marker-sub {
    background: #AAA
}

.noUi-marker-large {
    background: #AAA
}

.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%)
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%)
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%
}

.noUi-horizontal .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px
}

.noUi-vertical .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px
}

//  Custom styles

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 1px;
}
.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: -10px;
  margin-top: -2px;
  border: none;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background-color: rgba(182, 182, 182, 0.3);
  border-radius: 3px;
  border: none;
  box-shadow: none;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: $default;
  border-radius: 3px;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
  -webkit-transition: $general-transition-time $transition-ease;
  -moz-transition: $general-transition-time $transition-ease;
  -ms-transition: $general-transition-time $transition-ease;
  -o-transform: $general-transition-time $transition-ease;
  transition: $general-transition-time $transition-ease;
  &:before,
  &:after{
    content: none;
  }
  &:focus{
    outline: none;
  }
}
.noUi-active {
  -webkit-transform: scale3d(1.5, 1.5, 1);
  -moz-transform: scale3d(1.5, 1.5, 1);
  -ms-transform: scale3d(1.5, 1.5, 1);
  -o-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%, 0);
  transform: translate(-50%, 50%, 0);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.slider {
  &.slider-neutral {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $white;
    }

    &.noUi-target {
      background-color: rgba(255, 255, 255, 0.3);
    }

    & .noUi-handle {
      background-color: $white;
    }
  }

  &.slider-primary {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $primary;
    }

    &.noUi-target {
      background-color: $primary-opacity;
    }

    & .noUi-handle {
      background-color: $brand-primary;
      box-shadow: 0px 0px 10px 0px $brand-primary;
    }
  }

  &.slider-info {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $brand-info;
    }

    &.noUi-target {
      background-color: $info-opacity;
    }

    & .noUi-handle {
      background-color: $brand-info;
      box-shadow: 0px 0px 10px 0px $brand-info;
    }
  }
  &.slider-success {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $brand-success;
    }

    &.noUi-target {
      background-color: $success-opacity;
    }

    & .noUi-handle {
      background-color: $brand-success;
      box-shadow: 0px 0px 10px 0px $brand-success;
    }
  }
  &.slider-warning {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $brand-warning;
    }

    &.noUi-target {
      background-color: $warning-opacity;
    }

    & .noUi-handle {
      background-color: $brand-warning;
      box-shadow: 0px 0px 10px 0px $brand-warning;
    }
  }
  &.slider-danger {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $brand-danger;
    }

    &.noUi-target {
      background-color: $danger-opacity;
    }

    & .noUi-handle {
      background-color: $brand-danger;
      box-shadow: 0px 0px 10px 0px $brand-danger;
    }
  }
}
