.timeline {
    border-left: 3px solid #727cf5;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: rgba(#727cf5, 0.09);
    margin: 0 auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 40%;
    @media (max-width: 767px) {
        max-width: 98%;
        padding: 25px;
    }

    h1 {
        font-weight: 300;
        font-size: 1.4em;
    }

    h2,
    h3 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .event {
        border-bottom: 1px dashed #e8ebf1;
        padding-bottom: (50px * 0.5);
        margin-bottom: 25px;
        position: relative;
        width: 500px;

        @media (max-width: 767px) {
            padding-top: 30px;
        }

        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border: none;
        }

        &:before,
        &:after {
            position: absolute;
            display: block;
            top: 0;
        }

        &:before {
            left: (((120px * 0.6) + 50px + 4px + 4px + (4px * 2)) * 1.5) * -1;
            content: attr(data-date);
            text-align: right;
            font-weight: 100;
            font-size: 0.9em;
            min-width: 120px;
            @media (max-width: 767px) {
                left: 0;
                text-align: left;
            }
        }

        &:after {
            -webkit-box-shadow: 0 0 0 3px #727cf5;
            box-shadow: 0 0 0 3px #727cf5;
            left: (50px + 3px + (8px * 0.35)) * -1;
            background: #ffffff;
            border-radius: 50%;
            height: 9px;
            width: 9px;
            content: "";
            top: 5px;
            @media (max-width: 767px) {
                left: (25px + 4px + (8px * 0.35)) * -1;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
    .timeline-dot {
        .card {
            margin-left: 50px;
        }
        &:after,
        &:before {
            left: 35px;
        }
        .social-timelines:before {
            left: 38px;
        }
        .social-timelines-left:after {
            right: -50px;
        }
    }
}

.rtl {
    .timeline {
        border-left: 0;
        text-align: right;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-right: 3px solid theme-color(primary);
        .event {
            &::before {
                left: 0;
                right: -170px;
            }
            &::after {
                left: 0;
                right: -55.8px;
            }
        }
    }
}

@media only screen and (min-width: 1279px) and (max-width: 1500px) {
    .cd-horizontal-timeline {
        margin: 0 auto;
    }
    #chartdiv {
        height: 440px;
    }
    .social-card-1 .card-block-big {
        padding: 1.3em;
    }
    #morris-extra-area {
        height: 400px !important;
    }
    .widget-chat-box {
        .send-chat,
        .media .receive-chat,
        .receive-chat {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .similar-job .media {
        display: inline-block;
        .media-body {
            margin-top: 20px;
        }
    }
}